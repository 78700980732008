<template>
  <v-form ref="form" @submit.prevent="validateBeforeSubmit">
    <v-card>
      <v-card-title class="primary white--text">General Notes</v-card-title>
      <v-card-text>
        <v-col cols="12">
          <v-text-field
            label="Title"
            v-validate="'required'"
            v-model="vm.title"
            data-vv-as="title"
            name="title"
            :error-messages="errors.collect('title')"
          ></v-text-field>
        </v-col>

        <v-col cols="12">
          <v-textarea
            filled
            v-model="vm.details"
            label="Details"
            rows="5"
            data-vv-as="details"
            name="details"
            v-validate="'required'"
            :error-messages="errors.collect('details')"
          ></v-textarea>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close">Cancel</v-btn>
        <v-btn color="primary" type="submit">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>


<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import * as notesService from "../../../services/generalNotesService";
import { UserSummary } from "../../../models/userSummary";
import * as firebaseEmailService from "../../../services/firebaseEmailService";
import * as notificationService from "../../../services/notificationService";

Vue.use(VeeValidate);
export default {
  name: "notes-form",

  data: () => ({
    vm: {},
    emails: [],
    form: {}
  }),
  watch: {
    note(value) {
      if (value !== null && value !== undefined) {
        this.init();
      }
    }
  },
  mounted() {
    this.init();
  },
  props: {
    note: Object
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    }
  },
  methods: {
   async init() {
      if (this.note !== undefined) {
        this.vm = this.note;
      }
       await notificationService.getNoteById(this.currentOrganisation.id).then((doc) => {
        let temp = [];
        temp = doc.data();
        this.emails = temp.Emails;
        console.log(this.emails);
      });
    },

    close() {
      this.$emit("note-closed");
    },
    validateBeforeSubmit() {
      let me = this;
      this.$validator.validateAll().then(result => {
        if (result) {
          const userSummary =  Object.assign({}, new UserSummary(me.user));
          me.vm.modifiedOn = new Date();
          me.vm.modifiedBy = userSummary;
          if (me.vm.id == undefined) {
            me.vm.createdOn = new Date();
            me.vm.createdBy = userSummary;
          }
          me.vm.isDeleted=false;
          notesService
            .save(me.currentOrganisation.id, me.currentCase.id, me.vm)
            .then(ref => {
              if (ref != undefined) {
                me.vm.id = ref.id;
              }
              this.sendEmail();
              me.$store.dispatch("setToast", {
                message: "Note saved",
                color: "primary"
              });
              me.$emit("note-added", me.vm);
            });
        } else {
          this.$store.dispatch("setToast", {
            message: "Form errors please correct before continuing",
            color: "red darken-4"
          });
        }
      });
    },
    sendEmail()
    {
      let me = this;

      let participantFullname =me.currentCase.participantDetails.firstname +" " +me.currentCase.participantDetails.lastname;

      let fromID = me.user.email;
      let fromName = me.user.displayName;
      let toID = this.emails;
      let subject = "Omar notifications - new notes";
      let text = participantFullname + " has new general notes";

      let sendEmail = "Do you want to send an Email?";

      if (confirm(sendEmail) == true) {
        firebaseEmailService.sendNote(fromID, fromName, toID, subject, text);
      } else {
        console.log("not sent");
      }

    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  color: green;
  font-weight: bold;
}
</style>

